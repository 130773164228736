<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Редактирование подкатегории: {{ cat.name }}</div>
      <div class="btn-group">
        <a class="btn btn-success" @click="editVisibleSubCategory" v-if="cat.active == 0">Включить подкатегорию</a>
        <a class="btn btn-danger" @click="editVisibleSubCategory" v-else>Выключить подкатегорию</a>
        <router-link class="btn btn-outline-primary" :to="'/view-category/' + cat.category_id">Назад</router-link>
      </div>

    </div>
    <div class="form-group">
      <label class="form-label">Название</label>
      <input type="text" class="form-control" placeholder="Название" v-model="cat.name">
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Описание браузера</label>
      <input type="text" class="form-control" placeholder="Описание браузера" v-model="cat.title_browser">
    </div>
    <div class="form-group mt-3">
      <label class="mb-1">Полное описание</label>
      <quill-editor v-model="cat.desc"/>
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Мета описание</label>
      <input type="text" class="form-control" placeholder="Мета описание" v-model="cat.meta">
    </div>
    <div class="form-group mt-3">
      <label class="form-label">URL</label>
      <input type="text" class="form-control" placeholder="URL" v-model="cat.url">
      <div class="form-text">Пример: meat</div>
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Порядок</label>
      <input type="number" class="form-control" placeholder="Порядок" :min="0" v-model="cat.sort">
      <div class="form-text">От 1 и выше</div>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-success" @click="editSubCategory">Сохранить изменения</button>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'edit-sub-category',
  props: ['id'],
  data: function () {
    return {
      cat: {
        id: this.id,
        name: '',
        title_browser: '',
        desc: '',
        meta: '',
        url: '',
        sort: 0,
        category_id: 0,
        active: 0,
        products: [],
      },
    }
  },
  computed: {
    ...mapGetters(['sCategory', 'productListNoFilter']),
  },
  async mounted() {
    await this.getCategoryInfo();
  },
  methods: {
    getCategoryInfo: function(){
      this.$store.dispatch('getSubCategoriesInfo', this.id).then(() => {
        this.cat.name = this.sCategory.name;
        this.cat.title_browser = this.sCategory.title_browser;
        this.cat.desc = this.sCategory.desc;
        this.cat.meta = this.sCategory.meta;
        this.cat.url = this.sCategory.url;
        this.cat.sort = this.sCategory.sort;
        this.cat.category_id = this.sCategory.category_id;
        this.cat.active = this.sCategory.active;
      });
    },
    editVisibleSubCategory: function(){
      this.$store.dispatch('changeActiveSubCategory', this.id).then(() => {
        this.getCategoryInfo();
      });
    },
    editSubCategory: function(){
      this.$store.dispatch('editSubCategory', this.cat);
    },
  }
}
</script>