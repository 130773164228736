<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список шаблонов</div>
      <router-link class="btn btn-success" to="/designer-letter-template">Создать шаблон</router-link>
    </div>
    <table class="table table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Название</th>
        <th scope="col">Дата создания</th>
        <th scope="col">Дата редактирования</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in lettersTemplateInfo" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>
          <router-link :to="'/designer-letter-template/' + item.id">
            {{ item.name }}
          </router-link>
        </td>
        <td>{{ item.date }}</td>
        <td>{{ item.date_update }}</td>
        <td class="actions">
          <router-link :to="'/designer-letter-template/' + item.id">
            <i class="fas fa-pen"></i>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'list-letter-tempate',
  data: function () {
    return {

    }
  },
  computed: {
    ...mapGetters(['lettersTemplateInfo']),
  },
  async mounted() {
    this.getListLetter();
  },
  methods: {
    getListLetter: function(){
      this.$store.dispatch('listLetterTemplate');
    },
  }
}
</script>