import axios from 'axios';

export default {
    actions: {
        async searchYandexProducts(ctx, filter) {
            const res = await axios.get('/integration/search-yandex?search=' + filter.search);
            ctx.commit('updateYandexSearch', res.data.data);
        },
        async searchDeliveryProducts(ctx, filter) {
            const res = await axios.get('/integration/search-delivery?search=' + filter.search);
            ctx.commit('updateDeliverySearch', res.data.data);
        },
    },
    mutations: {
        updateYandexSearch(state, list) {
            state.yandex = list;
        },
        updateDeliverySearch(state, list) {
            state.delivery = list;
        }
    },
    state: {
        yandex: [],
        delivery: [],
    },
    getters: {
        yandexSearch: state => state.yandex,
        deliverySearch: state => state.delivery
    }
}
