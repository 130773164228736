import Vue from 'vue'
import Vuex from 'vuex'

// модули
import index from './modules/index/index'
import auth from './modules/auth/auth';
import users from './modules/users/users';
import category from "./modules/category/category";
import products from "./modules/products/products";
import orders from './modules/orders/orders';
import banners from './modules/banners/banners';
import callCenter from "./modules/call/call";
import settings from './modules/settings/settings';
import logs from './modules/logs/logs';
import promocodes from './modules/promocodes/promocodes';
import sms from './modules/sms/sms';
import profile from './modules/profile/profile';
import mailing from './modules/mailing/mailing';
import slots from './modules/stores/stores';
import integration from "./modules/integration/integration";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modal: {
            treeCategory: false,
            createPromocode: false,
            editPromocode: false,
            viewCall: false,
            createCategory: false,
            createSubCategory: false,
            sendSms: false,
            sendWhatsApp: false,
            createUser: false,
            editUser: false,
        },
    },
    mutations: {
        updateModalVisible(state, name){
            this.state.modal[name] = true;
        }
    },
    actions: {
        openModal(ctx, name){
            ctx.commit('updateModalVisible', name);
        },
    },
    getters: {},
    modules: {
        auth, index, users, category, products, orders, banners, callCenter, settings, logs, promocodes, sms, profile, mailing, slots, integration
    }
});
