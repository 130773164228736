<template>
  <div class="main-layout loading">
    <Sidebar/>
    <Body/>
  </div>
</template>

<script>
import Sidebar from '@/components/sidebar.vue';
import Body from '@/components/body.vue';
export default {
  name: 'MainLayout',
  components: {
    Sidebar, Body
  }
}
</script>