import axios from 'axios';
import Vue from "vue";

export default {
    actions: {
        async getProfile(ctx, id) {
            const res = await axios.post('/user/profile-get', id);
            ctx.commit('updateProfile', res.data.data.user);
        },
        async saveProfile(ctx, data){
            const res = await axios.post('/user/profile-save', data);
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        }
    },
    mutations: {
        updateProfile(state, profile){
            state.profile = profile;
        }
    },
    state: {
        profile: [],
    },
    getters: {
        profileGet(state){
            return state.profile;
        },
    }
}