<template>
  <section class="main">
    <div class="table-header">Создание заказа</div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            Информация о заказе
          </div>
          <div class="card-body">
            <div class="form-group mb-2">
              <label class="form-label">Выберите клиента</label>
              <multiselect id="ajax" v-model="order.selectedUsers" :options="options" placeholder="Начните поиск чтобы увидеть клиентов"
                           label="full_name" track-by="full_name" :searchable="true"
                           :internal-search="false" @search-change="searchUsers" :options-limit="300" :limit="12"></multiselect>
            </div>
            <div class="form-group mb-2">
              <div class="form-group">
                <label class="form-label">Регион</label>
                <select class="form-select" v-model="order.region" @change="getProductList">
                  <option value="77">Москва</option>
                  <option value="78">Санкт-Петербург</option>
                </select>
              </div>
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Адрес</label>
              <multiselect id="ajax" v-model="order.address" :options="options2" placeholder="Начните поиск чтобы увидеть адреса"
                           label="description" track-by="description" :searchable="true"
                           :internal-search="false" @search-change="yandexGeo" :options-limit="300" :limit="12"></multiselect>
            </div>
            <div class="row mb-2">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label">Кв/Офис</label>
                  <input class="form-control" type="text" v-model="order.number" placeholder="Кв/Офис">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label">Подъезд</label>
                  <input class="form-control" type="text" v-model="order.door" placeholder="Подъезд">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-label">Этаж</label>
                  <input class="form-control" type="text" v-model="order.floor" placeholder="Этаж">
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Комментарий</label>
              <textarea class="form-control" rows="4" v-model="order.comment"/>
            </div>
            <div class="row mb-2">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label">
                    Выберите интервал и дату доставки
                  </label>
                  <select class="form-select" v-model="order.date_interval">
                    <option value="">Выберите интервал</option>
                    <option v-for="(item, index) in slotsInfo" :value="item" :key="index">{{ item.start }} - {{ item.end }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label">Способ оплаты</label>
                  <select class="form-select" v-model="order.type_payment">
                    <option value="1">Наличные</option>
                    <option value="3">Безнал</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            Состав заказа
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <button class="btn btn-success" @click="order.countProducts++">Добавить товар</button>
            </div>
            <div class="row mt-2" v-for="index in order.countProducts" :key="index" :id="test">
              <div class="col-md-8">
                <div class="form-group">
                  <label class="form-label">Выберите товар ({{ order.products.hasOwnProperty(index) ? order.products[index].product.fullPrice : 0 }} руб.)</label>
                  <multiselect :value="order.products.hasOwnProperty(index) ? order.products[index].product : []" @input="updateProducts" :options="productListNoFilter" placeholder="Выберите" label="title" track-by="title"></multiselect>
                </div>
              </div>
              <div class="col-md-2" v-if="order.products.hasOwnProperty(index)">
                <div class="form-group">
                  <label class="form-label">Количество</label>
                  <input class="form-control" type="number" :min="1" v-model="order.products[index].product['addCount']" @change="calculatePrice(index)">
                </div>
              </div>
              <div class="col-md-2" v-if="order.products.hasOwnProperty(index)">
                <div class="form-group">
                  <label class="form-label">Коэф.</label>
                  <input class="form-control" type="text" v-model="order.products[index].product.coef" :disabled="true">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="d-flex justify-content-end">
        <button class="btn btn-success" @click="createOrder">Создать заказ</button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import Vue from "vue";

export default {
  name: 'create-order',
  data: function () {
    return {
      order: {
        region: 77,
        selectedUsers: [],
        address: '',
        number: '',
        door: '',
        floor: '',
        comment: '',
        date_interval: '',
        type_payment: 1,
        products: [],
        countProducts: 0,
      },
      options: [],
      options2: [],
      test: 1,
    }
  },
  computed: mapGetters(['productListNoFilter', 'slotsInfo']),
  async mounted() {
    this.getProductList();
    this.getSlotsList();
  },
  methods: {
    yandexGeo: function(value){
      if(value.length > 0){
        axios.defaults.baseURL = '';
        axios.get('https://geocode-maps.yandex.ru/1.x?geocode=' + value + '&apikey=ddb478df-6120-46a8-8331-ea67cefec68c&kind=house&rspn=1&bbox=20.742187,26.431228~188.789062,78.903929&format=json').then(response => {
          this.options2 = [];
          response.data.response.GeoObjectCollection.featureMember.forEach((value) => {
            this.options2.push({
              Point: value.GeoObject.Point,
              description: value.GeoObject.description + ' ' + value.GeoObject.name
            });
          });
        });
      }
    },
    updateProducts: function(val){
      val['addCount'] = 1;
      val['coef'] = val.peramount * val.addCount;
      let i = this.order.countProducts;
      this.$set(this.order.products, i, {'product': val});
      i++;
      this.calculatePrice(i);
    },
    getProductList: function(){
      this.$store.dispatch('getProductListNoFilter', this.order.region);
    },
    getSlotsList: function(){
      this.$store.dispatch('getSlotsList');
    },
    createOrder: function(){
      if(Vue.config.productionTip){
        axios.defaults.baseURL = 'https://api.halalfoods.ru/admin/';
      }else{
        axios.defaults.baseURL = 'http://halal-foods-api/admin/';
      }
      this.$store.dispatch('createOrder', this.order);
    },
    searchUsers: function(value){
      if(Vue.config.productionTip){
        axios.defaults.baseURL = 'https://api.halalfoods.ru/admin/';
      }else{
        axios.defaults.baseURL = 'http://halal-foods-api/admin/';
      }
      axios.get('/user/list-no-filter?full_name=' + value).then(response => {
        var status = response.data.result;
        if(status == 'ok'){
          this.options = response.data.data.users;
        }
      });
    },
    calculatePrice: function(index){
      if(this.order.products[index]){
        this.order.products[index].product.fullPrice = parseFloat(this.order.products[index].product.price * parseInt(this.order.products[index].product.addCount)).toFixed(2);
        this.order.products[index].product.coef = this.order.products[index].product.peramount * this.order.products[index].product.addCount;
        this.test++;
      }
    },
  },
  components: {
    Multiselect
  }
}
</script>
