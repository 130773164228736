<template>
  <div class="sidebar open">
    <div class="sidebar-top">
      <div class="name">
        Halalfoods.ru
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="menu flex-column">
        <router-link to="/" class="menu-item">
          <span>
            <i class="fas fa-home"></i>
            Главная</span>
        </router-link>
        <a class="menu-item dropdown" @click="openItem">
          <span>
            <i class="fas fa-store"></i>
            Интернет-магазин</span>
          <div class="dropdown-content">
            <router-link to="/list-orders">
              Заказы
            </router-link>
            <router-link to="/list-products">
              Товары
            </router-link>
            <router-link to="/list-category">
              Категории
            </router-link>
          </div>
        </a>
        <a class="menu-item dropdown" @click="openItem">
          <span>
            <i class="fas fa-search-dollar"></i>
            Маркетинг</span>
          <div class="dropdown-content">
            <router-link to="/list-banners">
              Баннеры
            </router-link>
            <router-link to="/promocodes">
              Промокоды
            </router-link>
            <router-link to="/list-mailing">
              Рассылки
            </router-link>
            <router-link to="/mailing/create">
              Создать рассылку (Beta)
            </router-link>
          </div>
        </a>
        <a class="menu-item dropdown" @click="openItem">
          <span>
            <i class="fas fa-phone-square-alt"></i>
            Call-центр</span>
          <div class="dropdown-content">
            <router-link to="/list-call">
              Обзвоны
            </router-link>
            <router-link to="/call-stats">
              Результаты
            </router-link>
            <router-link to="/screen-call">
              Экран call-центр
            </router-link>
          </div>
        </a>
        <a class="menu-item dropdown" @click="openItem">
          <span>
            Интеграции
          </span>
          <div class="dropdown-content">
            <router-link to="/integration/yandex">Yandex.Eda</router-link>
            <router-link to="/integration/delivery">Delivery Club</router-link>
          </div>
        </a>
        <router-link to="/list-users" class="menu-item">
          <span>
            <i class="fas fa-users"></i>
            Клиенты</span>
        </router-link>
        <a class="menu-item dropdown" @click="openItem">
          <span>
            <i class="fas fa-cogs"></i>
            Система
          </span>
          <div class="dropdown-content">
            <router-link to="/settings">
              Настройки
            </router-link>
            <router-link to="/logs">
              Логи
            </router-link>
          </div>
        </a>
      </ul>
    </div>
    <div class="sidebar-bottom">
      <div class="return-season">
        <i class="fas fa-undo-alt"></i>
        <a href="https://halalfoods.ru">Домой</a>
      </div>
      <div class="logout" @click="logout">
        <i class="fas fa-sign-out-alt"></i>
        <span>Выйти</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sidebar',
  data: function () {
    return {}
  },
  methods: {
    openItem: function (e) {
      var el = e.target;
      if (el.parentElement.classList.contains('open')) {
        el.parentElement.classList.remove('open');
      } else {
        el.parentElement.classList.add('open');
      }
    },
    logout: function () {
      this.$store.dispatch("logout");
    },
  },
}
</script>
