import axios from 'axios';
import Vue from "vue";

export default {
    actions: {
        async getUserList(ctx, filter) {
            const res = await axios.get('/user/list?limit=' + filter.visible
                            + '&search=' + filter.search
                            + '&accept=' + filter.acceptOrder
                            + '&last=' + filter.lastOrder
                            + '&avgBefore=' + filter.avgCheckBefore
                            + '&avgAfter=' + filter.avgCheckAfter);
            ctx.commit('updateUserList', res.data.data);
        },
        async getUserListNoFilter(ctx){
            const res = await axios.get('/user/list-no-filter');
            ctx.commit('updateUserListNoFilter', res.data.data.users);
        },
        async getUser(ctx, id) {
            const res = await axios.get('/user/get?id=' + id);
            ctx.commit('updateUser', res.data.data.user);
        },
        async addScore(ctx, data){
            const res = await axios.post('/user/add-score', data);
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        },
        async sendWhatsApp(ctx, data){
            const res = await axios.post('/whats-app/send', data);
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        },
        async removeScore(ctx, data){
            const res = await axios.post('/user/remove-score', data);
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        },
        async changeAdv(ctx, data) {
            const res = await axios.post('/user/change-adv', data);
            if (res.data.result == 'ok') {
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async createUser(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post("/user/create-user", data).then(res => {
                    resolve(res);
                }, error => {
                    reject(error);
                })
            })
        },
        async editUser(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post("/user/edit-user", data).then(res => {
                    resolve(res);
                }, error => {
                    reject(error);
                })
            })
        },
    },
    mutations: {
        updateUserList(state, users){
            state.users = users;
        },
        updateUserListNoFilter(state, users){
            state.usersNoFilter = users;
        },
        updateUser(state, user){
            state.user = user;
        }
    },
    state: {
        users: [],
        usersNoFilter: [],
        user: [],
    },
    getters: {
        userList(state){
            return state.users;
        },
        usersListNoFilter: state => state.usersNoFilter,
        userGet(state){
            return state.user;
        }
    }
}
