<template>
  <div class="container-fluid">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список продуктов ({{ productCount }})</div>
    </div>
    <div class="form-filter">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Поиск по названию</label>
            <input @keyup.enter="getProductList" type="text" class="form-control" placeholder="Поиск..." v-model="filter.search.title"/>
          </div>
        </div>
      </div>
      <div class="btn-toolbar mt-3">
        <div class="btn-group me-2">
          <button type="button" class="btn btn-primary" @click="showTreeCategoryModal">Выбрать категорию</button>
        </div>
        <div class="btn-group me-2">
          <button type="button" class="btn btn-secondary" @click="clearFilters">Сбросить фильтр</button>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="btn-group me-2">
          <button type="button" class="btn btn-success" @click="getProductList">Найти</button>
        </div>
      </div>
    </div>
    <p class="form-label mt-2 text-end">Показано: {{ this.filter.limit }} из {{ productCount }}</p>
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Фото</th>
        <th scope="col">Название</th>
        <th scope="col">Категории</th>
        <th scope="col">Цена</th>
        <th scope="col">Остаток</th>
        <th scope="col">Видимость</th>
        <th scope="col">Порядок</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody v-if="productList.length > 0">
      <tr v-for="(item, index) in productList" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>
          <img class="product-image" :src="'https://api.halalfoods.ru/' + item.eco_medium_image" v-if="item.eco_medium_image"/>
          <img class="product-image" :src="'https://api.halalfoods.ru/' + item.eco_big_image" v-else/>
        </td>
        <td>
          <a :href="'/view-product/' + item.id" target="_blank">
            {{ item.title }} ECO ID - {{ item.eco_id }}
          </a>
        </td>
        <td>
          <div class="product-category">
            <div class="category-item" v-for="category in item.cats" :key="category.id">
              {{ category.name }}
            </div>
          </div>
        </td>
        <td>
          <div class="category-item" v-for="product in item.productsAvailables" :key="product.id">
            {{ arrayCity.get(product.region) }} - {{ product.price }} ₽
          </div>
        </td>
        <td>
          <div class="category-item" v-for="product in item.productsAvailables" :key="product.id">
            {{ arrayCity.get(product.region) }} - {{ product.available }}
          </div>
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" @change="changeVisible(item.id)" :checked="parseInt(item.active)">
          </div>
        </td>
        <td>
          <input class="very-small" type="number" @change="editSort(item.id, $event.target.value)" placeholder="Порядок" :value="item.sort">
        </td>
        <td class="actions">
          <router-link :to="'/view-product/' + item.id">
            <i class="fas fa-eye"></i>
          </router-link>
        </td>
      </tr>
      </tbody>
      <tbody v-else>Ничего не найдено</tbody>
    </table>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-primary" @click="getMore" v-if="productList.length != 0">Загрузить ещё</a>
      </div>
    </div>
    <tree-category v-show="this.$store.state.modal.treeCategory"
                   @close="closeTreeCategoryModal"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TreeCategory from "../../components/modal/products/tree-category";

export default {
  name: 'list-products',
  components: {TreeCategory},
  data: function () {
    return {
      filter: {
        limit: 50,
        search: {
          title: '',
          barcode: '',
          priceFrom: 1,
          priceTo: 100000,
          weightFrom: 1,
          weightTo: 100000,
        },
      },
      arrayCity: new Map([
        ['77', 'Москва'],
        ['78', 'СПб']
      ]),
    }
  },
  computed: {
    ...mapGetters(['productList', 'productCount', 'productCategories']),
  },
  async mounted() {
    this.getProductList();
  },
  methods: {
    getMore: function () {
      this.filter.limit += 50;
      this.getProductList();
    },
    getProductList: function () {
      this.$store.dispatch('getProductList', this.filter);
    },
    changeVisible: function(id){
      this.$store.dispatch('changeActiveProduct', id);
    },
    editSort: function(id, value){
      this.$store.dispatch('editSortProduct', {'id': id, 'sort': value});
    },
    clearFilters: function () {
      this.filter.search.title = '';
      this.filter.search.barcode = '';
      this.filter.search.priceFrom = 1;
      this.filter.search.priceTo = 100000;
      this.filter.search.weightFrom = 1;
      this.filter.search.weightTo = 100000;
      this.$root.$emit('updateTreeCategories');
      this.$store.commit('updateFilterCategories', '');
      this.getProductList();
    },
    showTreeCategoryModal() {
      this.$store.state.modal.treeCategory = true;
    },
    closeTreeCategoryModal() {
      this.$store.state.modal.treeCategory = false;
    },
  },
}
</script>
