import axios from 'axios';
import Vue from "vue";

export default {
    actions: {
        async startAuth(ctx, data) {
            const res = await axios.post('/auth/login', data);
            if(res.data.result === 'error'){
                Vue.$toast.error(res.data.message);
            }else{
                ctx.commit('updateToken', res.data.data);
                window.location.href = '/';
            }
        },
        async logout(){
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('user_id');
            window.location.href = '/auth';
        },
    },
    mutations: {
        updateToken(state, user){
            localStorage.setItem('token', user.token);
            localStorage.setItem('user', user.full_name);
            localStorage.setItem('user_id', user.id);
            axios.defaults.headers = {Authorization: 'Bearer ' + user.token}
        }
    },
    state: {
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') || [],
        user_id: localStorage.getItem('user_id') || '',
    },
    getters: {
        isLoggedIn: state => !!state.token,
        user: state => state.user,
        user_id: state => state.user_id
    }
}