<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список рассылок</div>
      <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
        <div class="btn-group me-2" role="group" aria-label="Second group">
          <router-link class="btn btn-success" to="/list-letter">Список писем</router-link>
        </div>
        <div class="btn-group" role="group" aria-label="Third group">
          <router-link class="btn btn-success" to="/list-letter-template">Список шаблонов</router-link>
        </div>
      </div>

    </div>
    <table class="table table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Название</th>
        <th scope="col">Тема</th>
        <th scope="col">Статус</th>
        <th scope="col">Тип</th>
        <th scope="col">Активность</th>
        <th scope="col">Шаблон письма</th>
        <th scope="col">Дата отправки</th>
        <th scope="col">Дата создания</th>
        <th scope="col">Дата изменения</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in mailingList" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ item.name }}</td>
        <td>{{ item.subject }}</td>
        <td v-if="item.send == 0">Не отправлено</td>
        <td v-if="item.send == 1">В процессе</td>
        <td v-if="item.send == 2">Отправлено</td>
        <td v-if="item.type == 0">E-mail</td>
        <td v-if="item.active == 0">Не активно</td>
        <td v-if="item.active == 1">Активно</td>
        <td>
          <router-link :to="'/designer-mailing-template/' + item.mailing_id">
            Перейти
          </router-link>
        </td>
        <td>{{ item.date_send }}</td>
        <td>{{ item.date }}</td>
        <td>{{ item.date_update }}</td>
      </tr>
      </tbody>
    </table>
    <router-link class="btn btn-success" to="/designer-mailing">Создать рассылку</router-link>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: 'list-mailing',
  data: function () {
    return {

    }
  },
  computed: {
    ...mapGetters(['mailingList']),
  },
  async mounted() {
    this.getMailingList();
  },
  methods: {
    getMailingList: function(){
      this.$store.dispatch('listMailing');
    },
  }
}
</script>