<template>
  <div class="modal-backdrop" v-if="this.$store.state.modal.createSubCategory">
    <div class="modal-mask" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Создание подкатегории</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-1">
              <label class="col-form-label">Название</label>
              <input type="text" class="form-control" v-model="category.name" placeholder="Название подкатегории">
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">URL</label>
              <input type="text" class="form-control" v-model="category.url" placeholder="URL">
              <div class="form-text">Пример: meat</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Закрыть</button>
            <button type="button" class="btn btn-primary" @click="createSubCategory">Создать подкатегорию</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'create-sub-category',
  props: ['id'],
  data: function(){
    return{
      category: {
        category_id: this.id,
        name: '',
        url: '',
      }
    }
  },
  methods: {
    createSubCategory: function(){
      this.$store.dispatch('createSubCategory', this.category).then(() => {
        this.$root.$emit('updateSubCategoriesView');
      });
    },
    close: function() {
      this.$store.state.modal = false;
    },
  },
};
</script>
