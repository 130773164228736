import axios from 'axios';
import router from "../../../router";
import Vue from "vue";

export default {
    actions: {
        async getOrderList(ctx, filter) {
            const res = await axios.get('/order/list?limit=' + filter.limit
                + '&status=' + filter.status
                + '&phone=' + filter.phone
                + '&full_name=' + filter.fio
                + '&orderId=' + filter.orderId
                + '&address=' + filter.address
                + '&ecoId=' + filter.ecoId
            );
            ctx.commit('updateOrderList', res.data.data);
        },
        async getOrder(ctx, orderId) {
            await axios.get('/order/get?id=' + orderId).then(async res => {
                ctx.commit('updateOrder', res.data.data);
            });
        },
        async createOrder(ctx, data) {
            const res = await axios.post('/order/create', data);
            if (res.data.result == 'ok') {
                router.go(-1);
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
    },
    mutations: {
        updateOrderList(state, data) {
            state.orders = data.orders;
            state.totalCount = data.totalCount;
        },
        updateOrder(state, data) {
            state.order = data.order;
        },
    },
    state: {
        orders: [],
        order: [],
        totalCount: 0,
    },
    getters: {
        orderList(state) {
            return state.orders;
        },
        orderCount(state) {
            return state.totalCount;
        },
        order(state) {
            return state.order;
        }
    }
}