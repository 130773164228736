import axios from 'axios';

export default {
    actions: {
        async getLogs(ctx, limit) {
            const res = await axios.get('/logs/list?limit=' + limit);
            ctx.commit('updateLogsInfo', res.data.data.logs);
        },
    },
    mutations: {
        updateLogsInfo(state, data){
            state.logs = data;
        },
    },
    state: {
        logs: [],
    },
    getters: {
        logsInfo: state => state.logs,
    }
}
