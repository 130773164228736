<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header" v-if="!this.id">Создание письма</div>
      <div class="table-header" v-else>Редактирование письма: {{ this.letterInfo.name }}</div>
      <div class="btn-group">
        <router-link class="btn btn-secondary" to="/list-letter">Назад</router-link>
        <a class="btn btn-success" @click="save">Сохранить</a>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label">Название письма</label>
          <input type="text" class="form-control" placeholder="Название письма" v-model="name">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label">Выберите шаблон</label>
          <select class="form-select" v-model="templateSelected">
            <option value="0">Выберите шаблон</option>
            <option v-for="item in this.lettersTemplateInfo" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="defaultCheck1" v-model="needProduct">
          <label class="form-check-label" for="defaultCheck1">
            Выбрать товары
          </label>
        </div>
        <div class="form-group" v-if="needProduct">
          <label class="form-label">Выберите товары
            <vue-custom-tooltip label="Не забудьте добавить содержимое <html> и указать в нем текст {products}"><i
                class="far fa-question-circle"></i></vue-custom-tooltip>
          </label>
          <multiselect v-model="products" :options="this.productListNoFilter" placeholder="Выберите" label="title"
                       track-by="title" :multiple="true"></multiselect>
        </div>
      </div>
    </div>
    <EmailEditor style="height: 1000px"
                 ref="emailEditor"
                 :options="options"
                 :locale="locale"
                 v-on:load="editorLoaded"
                 v-on:ready="editorReady"
    />
  </section>
</template>

<script>
import {EmailEditor} from 'vue-email-editor';
import Multiselect from 'vue-multiselect';
import {mapGetters} from "vuex";

export default {
  name: 'designer-letter',
  props: ['id'],
  components: {
    EmailEditor, Multiselect
  },
  data: function () {
    return {
      locale: 'ru',
      name: '',
      jsonData: '',
      html: null,
      options: {},
      needProduct: false,
      products: [],
      templateSelected: 0,
    }
  },
  computed: {
    ...mapGetters(['letterInfo', 'productListNoFilter', 'lettersTemplateInfo']),
  },
  async mounted() {
    if (this.id) {
      this.getMailing();
    }
    this.getProductList();
    this.getListLetter();
  },
  methods: {
    getListLetter: function(){
      this.$store.dispatch('listLetterTemplate');
    },
    getProductList: function () {
      this.$store.dispatch('getProductListNoFilter');
    },
    getMailing: function () {
      this.$store.dispatch('getLetter', this.id).then(() => {
        this.name = this.letterInfo.name;
        this.jsonData = this.letterInfo.jsonData;
        this.templateSelected = this.letterInfo.template_id;
      });
    },
    editorLoaded() {

    },
    editorReady() {
      if (this.id) {
        this.$refs.emailEditor.editor.loadDesign(JSON.parse(this.jsonData));
      }
    },
    save() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        this.jsonData = design;
      });
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.html = data;
        if (this.id) {
          this.$store.dispatch('editLetter', {
            'id': this.id,
            'name': this.name,
            'json': this.jsonData,
            'html': this.html,
            'needProduct': this.needProduct,
            'products': this.products,
            'template': this.templateSelected
          });
        } else {
          this.$store.dispatch('saveLetter', {
            'name': this.name,
            'json': this.jsonData,
            'html': this.html,
            'needProduct': this.needProduct,
            'products': this.products,
            'template': this.templateSelected
          });
        }
      });
    },
  }
}
</script>