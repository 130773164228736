<template>
  <div class="container-fluid">
    <div class="row">
      <div id="auth-form" class="card border-primary">
        <h5 class="card-header bg-primary text-white">
          Вход</h5>
        <div class="card-body">
          <form class="panel-body">
            <div class="input-group">
            <span class="input-group-text">
              <i class="fa fa-user"></i>
            </span>
              <input type="text" v-model="auth.phone" class="form-control" placeholder="Телефон">
            </div>
            <div class="input-group">
            <span class="input-group-text">
              <i class="fa fa-lock"></i>
            </span>
              <input type="password" v-model="auth.password" class="form-control" placeholder="Пароль">
            </div>
            <button type="button" class="btn btn-primary" @click="login">Войти</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#auth-form {
  position: absolute;
  height: 200px;
  width: 400px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  padding: 0;
}
#auth-form .input-group {margin-bottom: 10px;}
#auth-form .panel-body {text-align: center;}
</style>

<script>
export default {
  name: 'login',
  data: function () {
    return {
      auth: {
        phone: '',
        password: '',
      },
      errors: []
    }
  },
  methods: {
    login: function() {
      this.$store.dispatch("startAuth", this.auth);
    }
  },
}
</script>