import axios from 'axios';

export default {
    actions: {
        async getIndexInfo(ctx) {
            const res = await axios.get('/index/info');
            ctx.commit('updateIndexInfo', res.data.data);
        },
    },
    mutations: {
        updateIndexInfo(state, info){
            state.statistics = info.ordersStatistics;
            state.statisticsChart = info.ordersLastMonth;
            state.avgCheck = info.avgCheckWeek;
            state.totalCount = info.countProductInfo.totalCount;
            state.total77Count = info.countProductInfo.total77Count;
            state.total78Count = info.countProductInfo.total78Count;
            state.total77ActiveCount = info.countProductInfo.total77ActiveCount;
            state.total78ActiveCount = info.countProductInfo.total78ActiveCount;
        }
    },
    state: {
        statistics: [],
        statisticsChart: [],
        avgCheck: [],
        totalCount: 0,
        total77Count: 0,
        total78Count: 0,
        total77ActiveCount: 0,
        total78ActiveCount: 0,

    },
    getters: {
        indexInfo: state => state.statistics,
        indexChart: state => state.statisticsChart,
        avgCheck: state => state.avgCheck,
        indexTotal: state => state.totalCount,
        indexTotal77: state => state.total77Count,
        indexTotal78: state => state.total78Count,
        indexTotalActive77: state => state.total77ActiveCount,
        indexTotalActive78: state => state.total78ActiveCount,
    }
}