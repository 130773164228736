<template>
  <section class="main">
    <div class="table-header">Логи</div>
    <table class="table table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Товар</th>
        <th scope="col">User</th>
        <th scope="col">Действие</th>
        <th scope="col">Что было</th>
        <th scope="col">Что стало</th>
        <th scope="col">Дата</th>
      </tr>
      </thead>
      <tbody v-if="logsInfo.length > 0">
      <tr v-for="(item, index) in logsInfo" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>
          <a class="link-primary" :href="'/view-product/' + item.id" target="_blank">{{ item.product.title }}</a>
        </td>
        <td>
          {{ item.user.full_name ? item.user.full_name : item.user.phone }}
        </td>
        <td>
          <template v-if="item.field == 'active'">
            Изменена видимость товара
          </template>
          <template v-if="item.field != 'active' && item.field != 'eco_medium_image'">
            Изменено свойства товара: {{ item.field }}
          </template>
          <template v-if="item.field == 'eco_medium_image'">
            Изменена фотография товара
          </template>
        </td>
        <td>
          <template v-if="item.field == 'active'">
            <template v-if="item.before == 1">
              Включен
            </template>
            <template v-else>
              Выключен
            </template>
          </template>
          <template v-if="item.field != 'active' && item.field != 'eco_medium_image'">
            <template>
              <template v-if="item.before == 1">
                Включено
              </template>
              <template v-else>
                Выключено
              </template>
            </template>
          </template>
          <template v-if="item.field == 'eco_medium_image'">
            {{ item.after.split('/')[4] }}
          </template>
        </td>
        <td>
          <template v-if="item.field == 'active'">
            <template v-if="item.after == 1">
              Включен
            </template>
            <template v-else>
              Выключен
            </template>
          </template>
          <template v-if="item.field != 'active' && item.field != 'eco_medium_image'">
            <template>
              <template v-if="item.after == 1">
                Включено
              </template>
              <template v-else>
                Выключено
              </template>
            </template>
          </template>
          <template v-if="item.field == 'eco_medium_image'">
            {{ item.before.split('/')[4] }}
          </template>
        </td>
        <td>
          {{ item.date }}
        </td>
      </tr>
      </tbody>
      <tbody v-else>Ничего не найдено</tbody>
    </table>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-primary" @click="getMore" v-if="logsInfo.length != 0">Загрузить ещё</a>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'logs',
  data: function () {
    return {
      limit: 12,
    }
  },
  computed: {
    ...mapGetters(['logsInfo']),
  },
  async mounted() {
    await this.getLogs();
  },
  methods: {
    getLogs: function () {
      this.$store.dispatch('getLogs', this.limit);
    },
    getMore: function () {
      this.limit += 12;
      this.getLogs();
    },
  }
}
</script>
