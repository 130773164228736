import axios from 'axios';
import Vue from "vue";

export default {
    actions: {
        async getRegions(ctx) {
            const res = await axios.get('/settings/list');
            ctx.commit('updateRegionInfo', res.data.data.regions);
        },
        async saveRegion(ctx, data){
            const res = await axios.post('/settings/region-save', data);
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        }
    },
    mutations: {
        updateRegionInfo(state, data){
            state.regions = data;
        },
    },
    state: {
        regions: [],
    },
    getters: {
        regionsInfo: state => state.regions,
    }
}