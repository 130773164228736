<template>
  <div class="modal-backdrop" v-if="this.$store.state.modal.viewCall">
    <div class="modal-mask" tabindex="-1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <span class="mr-2">Позвонить клиенту: {{ this.getCall.phone }} ({{ this.getCall.full_name }})</span><button class="btn btn-success" @click="callClient">Позвонить</button>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
          </div>
          <div class="modal-body fs-12">
            <div class="row">
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header">
                    Результат разговора
                  </div>
                  <div class="card-body-low">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="mb-1">Результат звонка</label>
                        <select class="form-select fs-12" v-model="resultCall.result">
                          <option value="">Выберите</option>
                          <option value="1">Звонок удался</option>
                          <option value="2">Клиент не отвечает</option>
                          <option value="3">Телефон выключен</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 mt-2">
                      <div class="form-group">
                        <label class="mb-1">Когда перезвонить</label>
                        <input class="form-control fs-12" type="datetime-local" placeholder="Дата и время" v-model="resultCall.timeReCall">
                      </div>
                    </div>
                    <div class="col-md-6 mt-2">
                      <div class="form-group">
                        <label class="mb-1">Почему не заказывает</label>
                        <select class="form-select fs-12" v-model="resultCall.notOrder">
                          <option value="0">Выберите</option>
                          <option value="1">Дорогие цены</option>
                          <option value="2">Вы опаздываете</option>
                          <option value="3">Плохое качество</option>
                          <option value="4">Дорогая доставка</option>
                          <option value="5">Был на отдыхе</option>
                          <option value="6">Уехал в другой город</option>
                          <option value="7">Пользуюсь другими сервисами</option>
                          <option value="8">Временно не в зоне доставки</option>
                          <option value="9">Забыли про вас</option>
                          <option value="10">Покупаю офлайн</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 mt-2">
                      <div class="form-group">
                        <label class="mb-1">Порекомендуете нас</label>
                        <select class="form-select fs-12" v-model="resultCall.recommend">
                          <option value="">Выберите</option>
                          <option value="0">Нет</option>
                          <option value="1">Да</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 mt-2">
                      <div class="form-group">
                        <label class="mb-1">Оцените нас</label>
                        <select class="form-select fs-12" v-model="resultCall.rate">
                          <option value="">Выберите</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <hr>
                    <div class="col-md-12 mt-2">
                      <textarea class="form-control fs-12" placeholder="Комментарий" rows="3" v-model="resultCall.comment"></textarea>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                      <button type="button" class="btn btn-primary" @click="createCall">Сохранить</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header">
                    Информация по клиенту
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label class="mb-1">
                        <b>Всего заказов: </b>
                        <template>{{ this.getCall.info.count > 0 ? this.getCall.info.count : this.getCall.total_order_count}} шт </template>
                        <router-link :to="'/view-user/' + this.getCall.id" target="_blank">
                          <i class="fas fa-eye"></i>
                        </router-link>
                      </label>
                    </div>
                    <hr>
                    <div class="form-group">
                      <label class="mb-1"><b>Средний чек:</b> <template>{{ this.getCall.info.avg_check > 0 ? parseFloat(this.getCall.info.avg_check).toFixed(2) : parseFloat(this.getCall.avg_check).toFixed(2) }} руб.</template></label>
                    </div>
                    <hr>
                    <div class="form-group">
                      <label class="mb-1"><b>Дата последнего заказа:</b> <template>{{ this.getCall.lastOrder.date ? this.getCall.lastOrder.date : this.getCall.last_order_date }}</template></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="card">
                  <div class="card-header">
                    Заказы пользователя
                  </div>
                  <div class="card-body-low">
                    <table class="table table-striped table-hover">
                      <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Сумма</th>
                        <th scope="col">Статус</th>
                        <th scope="col">Адрес</th>
                        <th scope="col">ECO ID <vue-custom-tooltip label="ID в системе эко маркета"><i class="far fa-question-circle"></i></vue-custom-tooltip></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in this.getOrders" :key="item.id">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ (parseFloat(item.amount) + parseFloat(item.delivery_price)).toFixed(2) }} руб.</td>
                        <td>{{ statusOrder(item.status) }}</td>
                        <td>{{ item.address }}</td>
                        <td>{{ item.eco_id }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header">
                    Отправка смс
                  </div>
                  <div class="card-body-low">
                    <div class="form-group mb-2">
                      <label class="mb-1">Количество баллов</label>
                      <input type="number" class="form-control fs-12" placeholder="Количество баллов" v-model="sms.score" :min="1" @keyup="changeScoreSms"/>
                    </div>
                    <div class="form-group">
                      <label class="mb-1">Текст</label>
                      <textarea class="form-control fs-12" placeholder="Текст" v-model="sms.text" rows="3"></textarea>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                      <button type="button" class="btn btn-success" @click="sendSms">
                        Отправить смс
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header">
                    Отправить сообщение в WhatsApp
                  </div>
                  <div class="card-body-low">
                    <div class="form-group mb-2">
                      <label class="mb-1">Количество баллов</label>
                      <input type="number" class="form-control fs-12" placeholder="Количество баллов" v-model="whats.score" :min="1" @keyup="changeScoreWhats"/>
                    </div>
                    <div class="form-group">
                      <label class="mb-1">Сообщение</label>
                      <textarea class="form-control fs-12" v-model="whats.sms" placeholder="Введите текст" rows="3"/>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                      <button type="button" class="btn btn-success" @click="sendWhatsApp">
                        Отправить
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header">
                    Начислить баллы
                  </div>
                  <div class="card-body-low">
                    <div class="form-group mb-3">
                      <label class="col-form-label">Количество</label>
                      <input type="number" class="form-control fs-12" v-model="scores.score" :min="1">
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" :value="scores.needDate" v-model="scores.needDate">
                      <label class="form-check-label">
                        Начислить на дату
                      </label>
                    </div>
                    <div class="form-group mb-3" v-if="scores.needDate">
                      <label class="col-form-label">Выберите дату</label>
                      <input type="datetime-local" class="form-control fs-12" v-model="scores.date">
                    </div>
                    <div class="d-flex justify-content-end">
                      <button type="button" class="btn btn-success mt-2" @click="addScore">
                        Начислить
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header">
                    Списать баллы
                  </div>
                  <div class="card-body-low">
                    <div class="form-group">
                      <label class="col-form-label">Количество</label>
                      <input type="number" class="form-control fs-12" v-model="rScore.score" :min="1">
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Причина списания</label>
                      <textarea class="form-control fs-12" v-model="rScore.comment" placeholder="Введите текст" rows="3"/>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                      <button type="button" class="btn btn-success" @click="removeScore">
                        Списать
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    Результаты обзонов
                  </div>
                  <div class="card-body">
                    <table class="table table-striped table-hover">
                      <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Менеджер</th>
                        <th scope="col">Дата</th>
                        <th scope="col">Статус</th>
                        <th scope="col">Когда перезвонить</th>
                        <th scope="col">Почему не заказыват</th>
                        <th scope="col">Порекомендуете нас</th>
                        <th scope="col">Оцените нас</th>
                        <th scope="col">Комментарий</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in this.getCall.callHistory" :key="item.id">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ item.manager.full_name ? item.manager.full_name : ''}}</td>
                        <td>{{ item.date }}</td>
                        <td>{{ statusCall(parseInt(item.result_call)) }}</td>
                        <td>{{ item.date_recall }}</td>
                        <td>{{ notOrder(parseInt(item.dont_order)) }}</td>
                        <td>{{ recommended(parseInt(item.recommended)) }}</td>
                        <td>{{ item.rate_us }}</td>
                        <td>{{ item.comment }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'view-call',
  props: ['id'],
  data: function(){
    return{
      resultCall: {
        users_id: 0,
        result: '',
        timeReCall: null,
        notOrder: 0,
        recommend: 0,
        rate: 1,
        comment: '',
      },
      sms: {
        score: 300,
        text: 'Воспользуйтесь скидкой 300 рублей, только на halalfoods.ru 💚',
        users_id: 0
      },
      scores: {
        users_id: this.id,
        score: 1,
        needDate: false,
        date: null,
      },
      rScore: {
        users_id: this.id,
        score: 1,
        comment: ''
      },
      whats: {
        score: 300,
        sms: 'Воспользуйтесь скидкой 300 рублей, только на halalfoods.ru 💚',
        users_id: this.id,
      }
    }
  },
  computed: {
    ...mapGetters(['getCall', 'getOrders']),
  },
  watch: {
    id: function (val) {
      if(val > 0){
        this.findCall();
      }
    },
  },
  methods: {
    removeScore: function(){
      this.$store.dispatch('removeScore', this.rScore).then(() => {
        this.rScore.score = 1;
        this.rScore.comment = '';
      });
    },
    sendWhatsApp: function(){
      this.$store.dispatch('sendWhatsApp', this.whats);
    },
    addScore: function(){
      this.$store.dispatch('addScore', this.scores).then(() => {
        this.scores.score = 1;
        this.scores.needDate = false;
        this.scores.date = null;
      });
    },
    callClient: function(){
      this.$store.dispatch('callClient', this.id);
    },
    changeScoreSms: function(){
      this.sms.text = 'Воспользуйтесь скидкой ' + this.sms.score + ' рублей, только на halalfoods.ru 💚';
    },
    changeScoreWhats: function(){
      this.whats.sms = 'Воспользуйтесь скидкой ' + this.whats.score + ' рублей, только на halalfoods.ru 💚';
    },
    sendSms: function(){
      this.$store.dispatch('sendSms', this.sms);
    },
    findCall: function(){
      this.$store.dispatch('startCall', this.id).then(() => {
        this.resultCall.users_id = this.id;
        this.scores.users_id = this.id;
        this.sms.users_id = this.id;
        this.whats.users_id = this.id;
        this.rScore.users_id = this.id;
      });
    },
    createCall: function(){
      this.$store.dispatch('createCall', this.resultCall);
    },
    statusOrder: function(value){
      let status = '';
      switch(value){
        case 'created':
          status = 'Создан';
          break;
        case 'canceled':
          status = 'Отменен';
          break;
        case 'delivered':
          status = 'Доставлен';
          break;
        case 'on_the_way':
          status = 'Доставляется';
          break;
        case 'handed_over_for_picking':
          status = 'Собран';
          break;
        case 'accepted':
          status = 'Принят';
          break;
        default:
          status = value;
          break;
      }
      return status;
    },
    statusCall: function(value){
      let status = '';
      switch(value){
        case 1:
          status = 'Звонок удался';
          break;
        case 2:
          status = 'Клиент не отвечает';
          break;
        case 3:
          status = 'Телефон выключен';
          break;
      }
      return status;
    },
    notOrder: function(value){
      let status = '';
      switch(value){
        case 1:
          status = 'Дорогие цены';
          break;
        case 2:
          status = 'Вы опаздываете';
          break;
        case 3:
          status = 'Плохое качество';
          break;
        case 4:
          status = 'Дорогая доставка';
          break;
        case 5:
          status = 'Был на отдыхе';
          break;
        case 6:
          status = 'Уехал в другой город';
          break;
        case 7:
          status = 'Пользуюсь другими сервисами';
          break;
        case 8:
          status = 'Временно не в зоне доставки';
          break;
        case 9:
          status = 'Забыли про вас';
          break;
        case 10:
          status = 'Покупаю офлайн';
          break;
        default:
          status = '-';
          break;
      }
      return status;
    },
    recommended: function(value){
      let status = '';
      switch(value){
        case 0:
          status = 'Нет';
          break;
        case 1:
          status = 'Да';
          break;
      }
      return status;
    },
    close: function() {
      this.$root.$emit('updateCallCenterList');
      this.$store.state.modal.viewCall = false;
    },
  },
};
</script>
