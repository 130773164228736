<template>
  <section class="main">
    <div class="row statistics-block">
      <div class="col-md-3">
        <div class="statistics-item">
          <div class="icon">
            <i class="fas fa-shopping-cart fa-2x"></i>
          </div>
          <div class="info-text">
            <span class="statistics-text">{{ indexInfo.totalOrderToday }}</span>
            <p class="text-muted mb-1">Заказов сегодня</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics-item">
          <div class="icon">
            <i class="fas fa-truck fa-2x"></i>
          </div>
          <div class="info-text">
            <span class="statistics-text">{{ indexInfo.totalOrderDeliveredToday }}</span>
            <p class="text-muted mb-1">Доставлено</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics-item">
          <div class="icon">
            <i class="fas fa-times fa-2x"></i>
          </div>
          <div class="info-text">
            <span class="statistics-text">{{ indexInfo.totalCanceledOrderToday }}</span>
            <p class="text-muted mb-1">Отмененных</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics-item">
          <div class="icon">
            <i class="fas fa-money-check-alt fa-2x"></i>
          </div>
          <div class="info-text">
            <span class="statistics-text">{{ parseFloat(indexInfo.averageCheckToday).toFixed(2) }} руб.</span>
            <p class="text-muted mb-1">Средний чек</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-3">
        <div class="card">
          <div class="card-header">
            Информация о товарах
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-center">Всего товаров на сайте: {{ indexTotal }}</li>
          </ul>
          <div class="row town-info">
            <div class="col-md-6">
              <div class="town-item">
                <span class="town">Москва</span>
                <p>Активных: {{ indexTotal77 }}</p>
                <p>В наличии: {{ indexTotalActive77 }}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="town-item">
                <span class="town">Санкт-Петербург</span>
                <p>Активных: {{ indexTotal78 }}</p>
                <p>В наличии: {{ indexTotalActive78 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="card">
          <div class="card-header">
            Количество заказов за месяц
          </div>
          <div class="card-body">
            <line-chart :chart-data="chartData" :height="100"></line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            Средний чек
          </div>
          <div class="card-body">
            <bar-chart :chart-data="avgCheckData" :height="200"></bar-chart>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            Выручка за неделю
          </div>
          <div class="card-body">
            <bar-chart :chart-data="amountWeekData" :height="200"></bar-chart>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LineChart from "./charts/line-chart";
import BarChart from "./charts/bar-chart";
import {mapGetters} from 'vuex';
export default {
  name: 'index',
  components: {LineChart, BarChart},
  data: function () {
    return {
      chartData: [],
      avgCheckData: [],
      amountWeekData: [],
    }
  },
  computed: {
    ...mapGetters(['indexInfo', 'indexChart', 'indexTotal', 'indexTotal77', 'indexTotal78', 'avgCheck', 'indexTotalActive77', 'indexTotalActive78']),
  },
  async mounted() {
    await this.getIndexInfo();
  },
  methods: {
    getIndexInfo: function(){
      this.$store.dispatch('getIndexInfo').then(() => {
        this.chartTotalOrder();
        this.chartAvgCheck();
        this.chartAmountWeek();
      });
    },
    chartTotalOrder: function(){
      this.chartData = {
        labels: this.indexChart.map(c => c.date),
        datasets: [
          {
            label: "Общее",
            data: this.indexChart.map(c => c.count),
            backgroundColor: [
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)'
            ],
            borderColor: [
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)'
            ],
            borderWidth: 1
          },
          {
            label: "Отмены",
            data: this.indexChart.map(c => c.canceled),
            backgroundColor: [
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
            ],
            borderColor: [
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
            ],
            borderWidth: 1
          },
        ],
      }
    },
    chartAvgCheck: function(){
      this.avgCheckData = {
        labels: this.avgCheck.map(c => c.date),
        datasets: [
          {
            label: "Средний чек",
            data: this.avgCheck.map(c => parseFloat(c.amount / c.count).toFixed(2)),
            backgroundColor: [
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)'
            ],
            borderColor: [
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)'
            ],
            borderWidth: 1
          },
        ],
      }
    },
    chartAmountWeek: function(){
      this.amountWeekData = {
        labels: this.avgCheck.map(c => c.date),
        datasets: [
          {
            label: "Выручка",
            data: this.avgCheck.map(c => parseFloat(c.amount).toFixed(2)),
            backgroundColor: [
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)'
            ],
            borderColor: [
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)'
            ],
            borderWidth: 1
          },
        ],
      }
    },
  },
}
</script>