import axios from 'axios';
import Vue from "vue";
import store from '../../store';

export default {
    actions: {
        async getListPromocode(ctx, data) {
            const res = await axios.get('/promocodes/list?limit=' + data.limit);
            ctx.commit('updatePromocodes', res.data.data.promocodes);
        },
        async createPromocode(ctx, data){
            const res = await axios.post('/promocodes/create', data);
            if(res.data.result == 'ok'){
                ctx.commit('updatePromocodes', res.data.data.promocodes);
                store.state.modal.createPromocode = false;
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        },
        async editPromocode(ctx, data){
            const res = await axios.post('/promocodes/edit', data);
            console.log(res.data)
            if(res.data.result == 'ok'){
                ctx.commit('updatePromocodes', res.data.data.promocodes);
                store.state.modal.editPromocode = false;
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        },
        async getPromocode(ctx, id){
            const res = await axios.post('/promocodes/get?id=' + id);
            ctx.commit('updatePromocode', res.data.data.promocode);
        }
    },
    mutations: {
        updatePromocodes(state, data){
            state.promocodes = data;
        },
        updatePromocode(state, data){
            state.promocode = data;
        }
    },
    state: {
        promocodes: [],
        promocode: [],
    },
    getters: {
        promocodesInfo: state => state.promocodes,
        promocodeInfo: state => state.promocode,
    }
}
