<template>
  <section class="screen">
    <table class="table table-hover table-bordered fs-12 screen-call-center">
      <thead>
      <tr>
        <th colspan="2"></th>
        <th colspan="6" class="text-center">Сегодня</th>
        <th colspan="7" class="text-center">Текущий месяц</th>
      </tr>
      <tr>
        <th>#</th>
        <th>Сотрудник</th>
        <th scope="col">Звонки</th>
        <th scope="col">Заказы</th>
        <th scope="col">RealTime</th>
        <th scope="col">Кон %</th>
        <th scope="col">Мин</th>
        <th scope="col">Заработок</th>
        <th scope="col">Звонки</th>
        <th scope="col">Заказы</th>
        <th scope="col">RealTime</th>
        <th scope="col">Кон 7</th>
        <th scope="col">Кон 30</th>
        <th scope="col">Мин</th>
        <th scope="col">Заработок</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in screenCall" :key="item.id">
        <td>{{ index + 1 }}</td>
        <td v-if="item.call_today == 0">{{ item.full_name ? item.full_name : 'Нет данных' }} <i class="fas fa-circle" style="color: red; font-size: 8px;"></i></td>
        <td v-else>{{ item.full_name ? item.full_name : 'Нет данных' }} <i class="fas fa-circle" style="color: green; font-size: 8px;"></i></td>
        <td style="color: #ffffff; background: #bd0000;" v-if="item.call_today < 200">{{ item.call_today }} / 200</td>
        <td v-else style="color: #ffffff; background: #32CD32;">{{ item.call_today }} / 200</td>
        <td v-if="item.award_today.length < 5">{{ item.award_today.length }} / 5</td>
        <td style="color: #ffffff; background: #92d050;" v-else>{{ item.award_today.length }} / 5</td>
        <td v-if="item.award_with_call_today == 0">0</td>
        <td v-else>{{ item.award_with_call_today }} / {{ parseFloat((item.award_with_call_today / item.call_today) * 100).toFixed(1) }}%</td>
        <td>{{ conversion[index] }}%</td>
        <td>0</td>
        <td>{{ calculateAward(item.award_today) }} руб.</td>

        <td style="color: #ffffff; background: #bd0000;" v-if="item.call_month < 4000">{{ item.call_month }} / 4000</td>
        <td style="color: #ffffff; background: #32CD32;" v-else>{{ item.call_month }} / 4000</td>
        <td v-if="item.award_month.length < 200">{{ item.award_month.length }} / 200</td>
        <td v-else>{{ item.award_month.length }} / 200</td>
        <td v-if="item.call_month == 0">0</td>
        <td v-else>{{ item.award_with_call_month }} / {{ parseFloat((item.award_with_call_month / item.call_month) * 100).toFixed(1) }}%</td>
        <td>{{ conversion7[index] }}%</td>
        <td>{{ conversion30[index] }}%</td>
        <td>0</td>
        <td>{{ calculateAward(item.award_month) }} руб.</td>
      </tr>
      <tr class="total">
        <td>{{ screenCall.length + 1 }}</td>
        <td>Итого</td>
        <td>{{ screenCallTotal.call_total_today }}</td>
        <td>{{ screenCallTotal.order_total_today }}</td>
        <td v-if="screenCallTotal.award_total_with_call_today == 0">0</td>
        <td v-else>{{ screenCallTotal.award_total_with_call_today }} / {{ parseFloat(screenCallTotal.award_total_with_call_today / screenCallTotal.call_total_today * 100).toFixed(1) }}%</td>
        <td v-if="screenCallTotal.call_total_today > 0 && screenCallTotal.order_total_today > 0">{{ parseFloat((screenCallTotal.call_total_today) / (screenCallTotal.amount_total_today) * 100).toFixed(1) }}%</td>
        <td v-else>0</td>
        <td>0</td>
        <td>{{ parseFloat(screenCallTotal.award_total_today).toFixed(2) }} руб.</td>
        <td>{{ screenCallTotal.call_total_month }}</td>
        <td>{{ screenCallTotal.order_total_month }}</td>
        <td v-if="screenCallTotal.award_total_with_call_month == 0">0</td>
        <td v-else>{{ screenCallTotal.award_total_with_call_month }} / {{ parseFloat(screenCallTotal.award_total_with_call_month / screenCallTotal.call_total_month * 100).toFixed(1) }}%</td>
        <td v-if="screenCallTotal.call_total_7days > 0 && screenCallTotal.order_total_7days > 0">{{ parseFloat((screenCallTotal.call_total_7days) / (screenCallTotal.award_total_7days) * 100).toFixed(1) }}%</td>
        <td v-else>0</td>
        <td v-if="screenCallTotal.call_total_month > 0 && screenCallTotal.order_total_month > 0">{{ parseFloat((screenCallTotal.call_total_month) / (screenCallTotal.amount_total_month) * 100).toFixed(1) }}%</td>
        <td v-else>0</td>
        <td>0</td>
        <td>{{ parseFloat(screenCallTotal.award_total_month).toFixed(2) }} руб.</td>
      </tr>
      </tbody>
    </table>
  </section>
</template>

<style scoped>
.screen-call-center{
  font-weight: bold;
}
.screen-call-center thead tr{
  background: #000000;
}
.screen-call-center thead tr:first-child th:nth-child(2){
  background: #5ca7ff;
  color: #000;
}
.screen-call-center thead tr:first-child th:nth-child(3){
  background: #ff7a00;
  color: #000;
}
.screen-call-center thead tr:nth-child(2) th{
  color: #ffffff;
}
.screen-call-center thead tr:nth-child(2) th:nth-child(8), .screen-call-center thead tr:nth-child(2) th:nth-child(15){
  background: #ffc00f;
  color: #000;
}
.screen-call-center tbody tr td{
  color: #ffffff;
}
.screen-call-center tbody tr td:nth-child(8), .screen-call-center tbody tr td:nth-child(15){
  background: #ffc00f;
  color: #000;
}
.screen-call-center tbody tr{
  background: #000000;
}
.screen-call-center tbody tr.total td:nth-child(8), .screen-call-center tbody tr.total td:nth-child(15){
  background: #000000;
  color: #ffffff;
}
</style>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'screen-call',
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters(['screenCall', 'screenCallTotal']),
    conversion: function(){
      return this.screenCall.map(function(item) {
        let call = parseInt(item.call_today);
        let amount = parseFloat(item.amount_order_today);
        if(call > 0 && amount > 0){
          return parseFloat((call) / (amount) * 100).toFixed(1);
        }
        return 0;
      });
    },
    conversion7: function(){
      return this.screenCall.map(function(item) {
        let call = parseInt(item.call_7_days);
        let amount = parseFloat(item.amount_order_7day);
        if(call > 0 && amount > 0){
          return parseFloat((call) / (amount) * 100).toFixed(1);
        }
        return 0;
      });
    },
    conversion30: function(){
      return this.screenCall.map(function(item) {
        let call = parseInt(item.call_month);
        let amount = parseFloat(item.amount_order_month);
        if(call > 0 && amount > 0){
          return parseFloat((call) / (amount) * 100).toFixed(1);
        }
        return 0;
      });
    },
  },
  async mounted() {
    this.$nextTick(function () {
      window.setInterval(() => {
        this.getScreenCall();
      },10000);
    });
    this.getScreenCall();
  },
  methods: {
    getScreenCall: function(){
      this.$store.dispatch('getScreenCall');
    },
    calculateAward: function (item) {
      let value = 0;

      item.forEach(function (item) {
        value += item.ruble;
      });

      return parseFloat(value).toFixed(2);
    },
  }
}
</script>