<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список клиентов</div>
      <a class="btn btn-success" @click="openClientModal">Создать клиента</a>
    </div>
    <div class="form-filter">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Поиск
              <vue-custom-tooltip label="Поиск по телефону, email или ФИО"><i class="far fa-question-circle"></i>
              </vue-custom-tooltip>
            </label>
            <input type="text" class="form-control" placeholder="Поиск..." v-model="filter.search"/>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Сколько раз заказывал</label>
            <input class="form-control" v-model="filter.acceptOrder">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Последний заказ до</label>
            <input class="form-control" type="datetime-local" v-model="filter.lastOrder">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Средний чек от</label>
            <input class="form-control" type="number" v-model="filter.avgCheckBefore">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Средний чек до</label>
            <input class="form-control" type="number" v-model="filter.avgCheckAfter">
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="btn-group mt-2">
          <button type="button" class="btn btn-success" @click="getUserList">Найти</button>
        </div>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">ФИО</th>
        <th scope="col">Email</th>
        <th scope="col">Телефон</th>
        <th scope="col">Уведомления</th>
        <th scope="col">Общее количество заказов</th>
        <th scope="col">Средний чек</th>
        <th scope="col">Дата последнего заказа</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in userList" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>
          <router-link class="link-primary" :to="'/view-user/' + item.id">
            {{ item.full_name ? item.full_name : '-' }}
          </router-link>
        </td>
        <td>{{ item.email ? item.email : '-' }}</td>
        <td>{{ item.phone }}</td>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :checked="parseInt(item.adv_messages)"
                   @change="changeAdvMessage(item.id)">
          </div>
        </td>
        <td>{{ item.total_order_count }}</td>
        <td>{{ item.avg_check }}</td>
        <td>{{ item.last_order_date }}</td>
        <td class="actions">
          <router-link :to="'/view-user/' + item.id">
            <i class="fas fa-eye"></i>
          </router-link>
          <a class="pointer" @click="openEditClientModal(item)">
            <i class="fas fa-pen"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-primary" @click="getMore">Загрузить ещё</a>
      </div>
    </div>
    <create-user/>
    <edit-user/>
  </section>
</template>

<script>
import createUser from '../../components/modal/users/create-user';
import {mapGetters} from 'vuex';
import EditUser from "../../components/modal/users/edit-user";

export default {
  name: 'list-users',
  components: {EditUser, createUser},
  data: function () {
    return {
      filter: {
        search: '',
        acceptOrder: '',
        lastOrder: this.getLastDate(),
        avgCheckBefore: '',
        avgCheckAfter: '',
        visible: 12,
      },
      isModalCreateUser: false,
    }
  },
  computed: mapGetters(['userList']),
  async mounted() {
    this.getUserList();
    this.$root.$on('updateClientList', () => {
      this.getUserList();
    });
  },
  methods: {
    changeAdvMessage: function (id) {
      this.$store.dispatch('changeAdv', {'id': id});
    },
    getLastDate: function () {
      var dateOffset = (24 * 60 * 60 * 1000) * 30;
      var timestamp = new Date();
      var todayDate = new Date(timestamp + dateOffset).toISOString().slice(0, -1);
      return todayDate;
    },
    getMore: function () {
      this.filter.visible += 12;
      this.getUserList();
    },
    getUserList: function () {
      this.$store.dispatch('getUserList', this.filter);
    },
    openClientModal: function () {
      this.$store.dispatch('openModal', 'createUser');
    },
    openEditClientModal: function (item) {
      this.$root.$emit('selectedClient', item);
      this.$store.dispatch('openModal', 'editUser');
    },
  },
}
</script>
