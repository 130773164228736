<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Создание баннера</div>
      <router-link class="btn btn-success" to="/list-banners">Назад</router-link>
    </div>
    <div class="mb-3">
      <label class="form-label">Название</label>
      <input type="text" class="form-control" placeholder="Название" v-model="banner.name">
    </div>
    <div class="mb-3">
      <label class="form-label">Месторасположение</label>
      <select class="form-select" v-model="banner.page">
        <option v-for="item in placeList" :key="item.id" :value="item.id">{{ item.name }}</option>
      </select>
    </div>
    <div class="mb-3">
      <label class="form-label">Ссылка для веб</label>
      <input type="text" class="form-control" placeholder="Ссылка" v-model="banner.url">
      <div class="form-text">Пример: /product/govyadina_myakot_kubikom</div>
    </div>
    <div class="mb-3">
      <label class="form-label">Выберите товар</label>
      <multiselect v-model="banner.product" :options="this.productListNoFilter" placeholder="Выберите" label="title" track-by="title"></multiselect>
      <div class="form-text">Для мобильного приложения</div>
    </div>
    <div class="mb-3">
      <label class="form-label">Выберите категорию</label>
      <multiselect v-model="banner.category" :options="this.categoryInfo" placeholder="Выберите" label="name" track-by="name"></multiselect>
      <div class="form-text">Для мобильного приложения</div>
    </div>
    <div class="mb-3">
      <label class="form-label">Изображение</label>
      <input class="form-control" type="file" ref="image_web" @change="webUpload">
    </div>
    <div class="mb-3">
      <label class="form-label">Изображение для мобильного</label>
      <input class="form-control" type="file" ref="image_mob" @change="mobUpload">
    </div>
    <div class="mb-3">
      <label class="form-label">Регион показа</label>
      <select class="form-select" v-model="banner.region">
        <option value="77">Москва</option>
        <option value="78">Санкт-Петербург</option>
      </select>
    </div>
    <div class="mb-3">
      <label class="form-label">Порядок</label>
      <input type="number" class="form-control" placeholder="Порядок" :min="1" v-model="banner.sort">
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" @click="createBanner">Создать</button>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';

export default {
  name: 'create-banner',
  data: function () {
    return {
      banner: {
        name: '',
        page: 0,
        url: '',
        image_web: null,
        image_mob: null,
        region: 77,
        product: [],
        category: [],
        sort: 1
      },
    }
  },
  computed: mapGetters(['placeList', 'productListNoFilter', 'categoryInfo']),
  async mounted() {
    this.getPlaceList();
    this.getProductList();
    this.getCategory();
  },
  methods: {
    createBanner: function(){
      this.$store.dispatch('createBanner', this.banner);
    },
    getPlaceList: function(){
      this.$store.dispatch('getBannerPlace');
    },
    getProductList: function(){
      this.$store.dispatch('getProductListNoFilter');
    },
    getCategory: function(){
      this.$store.dispatch('getCategories');
    },
    webUpload: function(){
      this.banner.image_web = this.$refs.image_web.files[0];
    },
    mobUpload: function(){
      this.banner.image_mob = this.$refs.image_mob.files[0];
    },
  },
  components: {
    Multiselect
  }
}
</script>