import axios from 'axios';
import Vue from "vue";

export default {
    actions: {
        async sendSms(ctx, data){
            const res = await axios.post('/sms/send-sms', data);
            if(res.data.result == 'ok'){
                Vue.$toast.success(res.data.message);
            }else{
                Vue.$toast.error(res.data.message);
            }
        }
    },
    mutations: {

    },
    state: {

    },
    getters: {

    }
}