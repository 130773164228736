<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Создание рассылки</div>
      <div class="btn-group">
        <router-link class="btn btn-secondary" to="/list-mailing">Назад</router-link>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label">Название рассылки</label>
      <input type="text" class="form-control" placeholder="Название рассылки" v-model="mailing.name">
    </div>
    <div class="mb-3">
      <label class="form-label">Тема письма</label>
      <input type="text" class="form-control" placeholder="Тема письма" v-model="mailing.subject">
    </div>
    <div class="mb-3">
      <label class="form-label">Дата отправки</label>
      <input type="datetime-local" class="form-control" placeholder="Заголовок письма" v-model="mailing.date_send">
      <div class="form-text">Если дата меньше или равна текущему времени, рассылка отправится сразу же</div>
    </div>
    <div class="mb-3">
      <label class="form-label">Выберите письмо</label>
      <multiselect v-model="mailing.letter" :options="this.lettersInfo" placeholder="Выберите" label="name"
                   track-by="name"></multiselect>
    </div>
    <hr>
    <div class="row">
      <label class="form-label"><b>Автоматические отсортированные получатели: <u>{{ this.countClients }}</u></b></label>
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label">Последний заказ от
            <vue-custom-tooltip label="Необходимо выбрать обе даты"><i class="far fa-question-circle"></i>
            </vue-custom-tooltip>
          </label>
          <input type="date" class="form-control" placeholder="От" v-model="filter.lastOrderBefore">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label">Последний заказ до
            <vue-custom-tooltip label="Необходимо выбрать обе даты"><i class="far fa-question-circle"></i>
            </vue-custom-tooltip>
          </label>
          <input type="date" class="form-control" placeholder="До" v-model="filter.lastOrderAfter">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label">Средний чек от</label>
          <input type="text" class="form-control" placeholder="От" v-model="filter.avgCheckBefore">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label">Средний чек до</label>
          <input type="text" class="form-control" placeholder="До" v-model="filter.avgCheckAfter">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label">Кол-во заказов от</label>
          <input type="text" class="form-control" placeholder="Кол-во заказов от" v-model="filter.orderCountBefore">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label">Кол-во заказов до</label>
          <input type="text" class="form-control" placeholder="Кол-во заказов до" v-model="filter.orderCountAfter">
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-start mt-3">
      <button class="btn btn-success" @click="getCountClients">Рассчитать клиентов</button>
    </div>
    <div class="row mt-2">
      <label class="form-label"><b>Выбранные получатели в ручную: <u>{{ mailing.selectedUsers.length }}</u></b></label>
      <div class="col-md-6">
        <multiselect id="ajax" v-model="mailing.selectedUsers" :options="options" placeholder="Начните поиск чтобы увидеть клиентов"
                     label="full_name" track-by="full_name" :multiple="true" :searchable="true"
                     :internal-search="false" @search-change="searchUsers" :options-limit="300" :limit="12"></multiselect>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" @click="createMailing">Создать</button>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  name: 'designer-mailing',
  data: function () {
    return {
      mailing: {
        name: '',
        subject: '',
        header: '',
        date_send: null,
        letter: null,
        selectedUsers: [],
      },
      filter: {
        lastOrderBefore: null,
        lastOrderAfter: null,
        avgCheckBefore: 0,
        avgCheckAfter: 100000,
        orderCountBefore: 0,
        orderCountAfter: 100000
      },
      options: [],
    }
  },
  computed: {
    ...mapGetters(['lettersInfo', 'countClients']),
  },
  async mounted() {
    this.getListLetters();
    this.getCountClients();
  },
  methods: {
    getListLetters: function(){
      this.$store.dispatch('listLetters');
    },
    searchUsers: function(value){
      axios.get('/user/list-no-filter?full_name=' + value).then(response => {
        var status = response.data.result;
        if(status == 'ok'){
          this.options = response.data.data.users;
        }
      });
    },
    getCountClients: function () {
      this.$store.dispatch('getClientsForMailing', this.filter);
    },
    createMailing: function () {
      this.$store.dispatch('createMailing', {'mailing': this.mailing, 'filter': this.filter});
    },
  },
  components: {
    Multiselect
  }
}
</script>