<template>
  <section class="main">
    <div class="table-header">Настройки интернет - магазина</div>
    <div class="row">
      <div class="col-md-3" v-for="item in regionsInfo" :key="item.region">
        <div class="card">
          <div class="card-header">
            Регион: {{ item.name }}
          </div>
          <div class="card-body">
            <div class="form-group">
              <label class="form-label">Минимальная сумма на заказ для сайта</label>
              <input type="text" class="form-control" placeholder="Сумма" v-model="region['r'+item.region].minWeb">
            </div>
            <div class="form-group mt-3">
              <label class="form-label">Минимальная сумма на заказ для приложения</label>
              <input type="text" class="form-control" placeholder="Сумма" v-model="region['r'+item.region].minMob">
            </div>
            <div class="d-flex justify-content-end mt-3">
              <button class="btn btn-success" @click="saveRegionInfo(item.region)">Сохранить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'settings',
  data: function () {
    return {
      region: {
        r77: {
          region: 0,
          minWeb: 0,
          minMob: 0
        },
        r78: {
          region: 0,
          minWeb: 0,
          minMob: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['regionsInfo']),
  },
  async mounted() {
    await this.getRegionInfo();
  },
  methods: {
    getRegionInfo: function () {
      let $this = this;
      this.$store.dispatch('getRegions').then(() => {
        this.regionsInfo.map(function (value) {
          $this.region['r'+value['region']].region = value['region'];
          $this.region['r'+value['region']].minWeb = value.min_amount_order_pc;
          $this.region['r'+value['region']].minMob = value.min_amount_order_mobile;
        });
      });
    },
    saveRegionInfo: function (region) {
      this.$store.dispatch('saveRegion', this.region['r'+region]);
    },
  }
}
</script>