<template>
  <section class="main">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <strong>Редактирование заказа №{{ this.order.id }} ({{ this.order.eco_id }}
              <vue-custom-tooltip label="Номер заказа на ecomarket.ru"><i class="far fa-question-circle"></i>
              </vue-custom-tooltip>
              )
            </strong>
            <span class="badge bg-warning" v-if="this.order.total_order_count == 1">Первый заказ клиента</span>
            <br>
            <span class="badge bg-secondary" v-if="this.order.status_payment == 0">Не оплачен</span>
            <span class="badge bg-info" v-else-if="this.order.status_payment == 1">Средства зарезервированы</span>
            <span class="badge bg-success" v-else-if="this.order.status_payment == 2">Оплачен</span>
            <span class="badge bg-info ml-1" v-if="this.order.status == 'created'">Создан</span>
            <span class="badge bg-warning ml-1" v-else-if="this.order.status == 'handed_over_for_picking'">Собран</span>
            <span class="badge bg-secondary ml-1" v-else-if="this.order.status == 'on_the_way'">Доставляется</span>
            <span class="badge bg-success ml-1" v-else-if="this.order.status == 'delivered'">Доставлен</span>
            <span class="badge bg-danger ml-1" v-else-if="this.order.status == 'canceled'">Отменен</span>
            <br>
            <p class="fs-12">Дата создания: {{ this.order.date }} <span v-if="this.order.date_update">| Последнее обновление </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
        <div class="btn-group me-2" role="group" aria-label="First group">
          <button type="button" class="btn btn-warning" @click="openSmsModal()">Отправить СМС</button>
        </div>
        <div class="btn-group me-2" role="group" aria-label="Second group">
          <button type="button" class="btn btn-warning" @click="openWhatsAppModal()">Отправить WA</button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            Доставка
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label">Интервал</label>
                  <input class="form-control" type="text" :disabled="true" :value="this.order.time_interval">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label">Дата</label>
                  <input class="form-control" type="text" :disabled="true" :value="this.order.date_interval">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            Промокод или баллы
          </div>
          <div class="card-body">
            <div class="form-group" v-if="this.order.promocodes_id > 0">
              <label class="form-label">Промокод</label>
              <input class="form-control" type="text" disabled="true" :value="this.order.code">
              <div class="form-text">
                {{ this.order.desc }}
              </div>
            </div>
            <div class="form-group" v-else-if="this.order.amount_bonuses">
              <label class="form-label">Баллы</label>
              <input class="form-control" type="text" disabled="true" :value="this.order.amount_bonuses">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" v-if="this.order.payments && this.order.payments.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            Оплаты
          </div>
          <div class="card-body">
            <table class="table table-hover table-bordered fs-12">
              <thead>
              <tr>
                <th scope="col">Дата</th>
                <th scope="col">Номер</th>
                <th scope="col">Сумма</th>
                <th scope="col">Этап</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in this.order.payments" :key="item.id">
                <td>{{ item.date }}</td>
                <td>{{ item.transaction_id }}</td>
                <td>{{ item.amount }} руб.</td>
                <td>{{ item.stage }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            Информация
          </div>
          <div class="card-body">
            <table class="table table-bordered table-hover fs-12">
              <tbody>
              <tr>
                <td>
                  <strong>Способ оплаты:</strong>
                </td>
                <td v-if="this.order.type_payment == 1">
                  Наличные
                </td>
                <td v-if="this.order.type_payment == 3">
                  Безналичные
                </td>
                <td>
                  <strong>Доставка:</strong>
                </td>
                <td>
                  Доставка курьером<br>{{ this.order.address }}<br>{{ this.order.date_interval }} - {{ this.order.time_interval }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Дата создания:</strong>
                </td>
                <td>
                  {{ this.order.date }}
                </td>
                <td>
                  <strong>Сумма доставки:</strong>
                </td>
                <td>
                  {{ this.order.delivery_price }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Cоздал заказ:</strong>
                </td>
                <td>
                  Клиент сам
                </td>
                <td>
                  <strong>Промокод</strong>
                </td>
                <td v-if="this.order.promocodes_id > 0">
                  {{ this.order.code }} ({{ this.order.desc }})
                </td>
                <td v-else>
                  -
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Клиент:</strong>
                </td>
                <td>
                  {{ this.order.full_name }}<br>{{ this.order.phone }}
                </td>
                <td>
                  <strong>Сумма документа:</strong>
                </td>
                <td>
                  {{ this.order.amount }} руб.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Кладовщик:</strong>
                </td>
                <td>
                </td>
                <td>
                  <strong>Комментарий:</strong>
                </td>
                <td>
                  {{ this.order.comment }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Бонусов начислено:</strong>
                </td>
                <td v-if="this.order.status == 'delivered'">
                  {{ parseFloat((this.order.amount / 100) * 5).toFixed(0) }}
                </td>
                <td v-else>
                  0
                </td>
                <td>
                  <strong>Бонусов потрачено:</strong>
                </td>
                <td v-if="this.order.amount_bonuses > 0">
                  {{ this.order.amount_bonuses  }}
                </td>
                <td v-else>0</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            Состав заказа
          </div>
          <div class="card-body">
            <table class="table table-hover table-bordered fs-12">
              <thead>
              <tr>
                <th scope="col" class="text-center">№</th>
                <th scope="col" class="text-center">Изображение</th>
                <th scope="col" class="text-center">ID</th>
                <th scope="col" class="text-center">ECO ID</th>
                <th scope="col" class="text-center">Наименование</th>
                <th scope="col" class="text-center">Цена</th>
                <th scope="col" class="text-center">Количество</th>
                <th scope="col" class="text-center">Сумма</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in this.order.products" :key="item.id">
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center" v-if="item.image">
                  <img style="width: 40px;" :src="'https://api.halalfoods.ru/' + item.image">
                </td>
                <td class="text-center" v-else>
                  -
                </td>
                <td class="text-center" >{{ item.id }}</td>
                <td class="text-center" >{{ item.eco_id }}</td>
                <td class="text-center" >{{ item.title }}</td>
                <td class="text-center" >{{ item.price }} руб.</td>
                <td class="text-center" >{{ item.quantity }}</td>
                <td class="text-center" >{{ parseFloat(item.price * item.quantity).toFixed(2) }} руб.</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <send-sms :id="selectedUser"/>
    <send-whats-app :id="selectedUser"/>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import SendSms from "../../components/modal/users/send-sms";
import SendWhatsApp from "../../components/modal/users/send-whats-app";

export default {
  name: 'view-order',
  components: {SendWhatsApp, SendSms},
  props: ['id'],
  data: function () {
    return {
      item: [],
      selectedUser: 0,
    }
  },
  computed: {
    ...mapGetters(['order']),
  },
  async mounted() {
    this.getOrder();
  },
  methods: {
    openWhatsAppModal: function(){
      this.selectedUser = this.order.users_id;
      this.$store.dispatch('openModal', 'sendWhatsApp');
    },
    openSmsModal: function(){
      this.selectedUser = this.order.users_id;
      this.$store.dispatch('openModal', 'sendSms');
    },
    getOrder: function () {
      this.$store.dispatch('getOrder', this.id);
    },
  },
}
</script>
