<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Результаты работы Call-центра</div>
    </div>
    <table class="table table-hover table-bordered fs-12">
      <thead>
      <tr>
        <th scope="col" class="text-center">Сотрудник</th>
        <th scope="col">Количество звонков сегодня</th>
        <th scope="col">Количество звонков за месяц</th>
        <th scope="col">Количество звонков за прошлый месяц</th>
        <th scope="col">Количество заказов сегодня</th>
        <th scope="col">Количество заказов за этот месяц</th>
        <th scope="col">Количество заказов за прошлый месяц</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in callListStats" :key="item.id">
        <td class="text-center">{{ item.full_name }}</td>
        <td class="text-center">{{ item.call_today }}</td>
        <td class="text-center">{{ item.call_month }}</td>
        <td class="text-center">{{ item.call_last_month }}</td>
        <td class="text-center">
          {{ item.award_today.length }} шт на {{ parseFloat(item.amount_order_today).toFixed(2) }} руб.
          <br>
          <span>Заработок {{ calculateAward(item.award_today) }} руб.</span>
          <br>
          <span>0 дней - {{ countAwardByType(item.award_today, 0) }} шт</span>
          <br>
          <span>20-49 дней - {{ countAwardByType(item.award_today, 1) }} шт</span>
          <br>
          <span>50-89 дней - {{ countAwardByType(item.award_today, 2) }} шт</span>
          <br>
          <span>90-149 дней - {{ countAwardByType(item.award_today, 3) }} шт</span>
          <br>
          <span>150-249 дней - {{ countAwardByType(item.award_today, 4) }} шт</span>
          <br>
          <span>250 дней - {{ countAwardByType(item.award_today, 5) }} шт</span>
        </td>
        <td class="text-center">
          {{ item.award_month.length }} шт на {{ parseFloat(item.amount_order_month).toFixed(2) }} руб.
          <br>
          <span>Заработок {{ calculateAward(item.award_month) }} руб.</span>
          <br>
          <span>0 дней - {{ countAwardByType(item.award_month, 0) }} шт</span>
          <br>
          <span>20-49 дней - {{ countAwardByType(item.award_month, 1) }} шт</span>
          <br>
          <span>50-89 дней - {{ countAwardByType(item.award_month, 2) }} шт</span>
          <br>
          <span>90-149 дней - {{ countAwardByType(item.award_month, 3) }} шт</span>
          <br>
          <span>150-249 дней - {{ countAwardByType(item.award_month, 4) }} шт</span>
          <br>
          <span>250 дней - {{ countAwardByType(item.award_month, 5) }} шт</span>
        </td>
        <td class="text-center">
          {{ item.award_last_month.length }} шт на {{ parseFloat(item.amount_order_last_month).toFixed(2) }} руб.
          <br>
          <span>Заработок {{ calculateAward(item.award_last_month) }} руб.</span>
          <br>
          <span>0 дней - {{ countAwardByType(item.award_last_month, 0) }} шт</span>
          <br>
          <span>20-49 дней - {{ countAwardByType(item.award_last_month, 1) }} шт</span>
          <br>
          <span>50-89 дней - {{ countAwardByType(item.award_last_month, 2) }} шт</span>
          <br>
          <span>90-149 дней - {{ countAwardByType(item.award_last_month, 3) }} шт</span>
          <br>
          <span>150-249 дней - {{ countAwardByType(item.award_last_month, 4) }} шт</span>
          <br>
          <span>250 дней - {{ countAwardByType(item.award_last_month, 5) }} шт</span>
        </td>
      </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'list-call-stats',
  data: function () {
    return {
      filter: {
        limit: 100,
      }
    }
  },
  computed: {
    ...mapGetters(['callListStats']),
  },
  async mounted() {
    this.getCallListStats();
  },
  methods: {
    calculateAward: function (item) {
      let value = 0;

      item.forEach(function (item) {
        value += item.ruble;
      });

      return parseFloat(value).toFixed(2);
    },
    countAwardByType: function (item, type) {
      let value = 0;

      item.forEach(function (item) {
        if(item.type == type){
          value++;
        }
      });

      return value;
    },
    getCallListStats: function () {
      this.$store.dispatch('getCallStats', this.filter);
    },
  }
}
</script>