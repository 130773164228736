import Vue from 'vue';
import App from './App.vue';
import * as Bootstrap from 'bootstrap';
import router from './router';
import store from './store/store';
import Lodash from 'lodash';
import axios from 'axios';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';
import LiquorTree from 'liquor-tree';
import VueQuillEditor from 'vue-quill-editor'
import VueToast from 'vue-toast-notification';
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(VueQuillEditor);
Vue.use(LiquorTree);
Vue.use(VueCustomTooltip);
Vue.use(Bootstrap);
Vue.use(Lodash);
Vue.use(VueToast, {'position': 'top-right'});
Vue.use(CKEditor);

Vue.config.productionTip = true;

if(Vue.config.productionTip){
    axios.defaults.baseURL = 'https://api.halalfoods.ru/admin/';
}else{
    axios.defaults.baseURL = 'http://halal-foods-api/admin/';
}

const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers = {Authorization: 'Bearer ' + token}
}

axios.interceptors.request.use(function(config) {
    if(!document.querySelector('.backdrop')){
        let el = document.createElement('div');
        let el2 = document.createElement('div');
        let el3 = document.createElement('div');
        let el4 = document.createElement('span');
        el.classList.add('backdrop');
        el2.classList.add('loader');
        el3.classList.add('spinner-border');
        el3.style.cssText = 'width: 3rem; height: 3rem;';
        el4.classList.add('sr-only');
        document.body.append(el);
        el.appendChild(el2);
        el2.appendChild(el3);
        el3.appendChild(el4);
    }
    return config;
});

axios.interceptors.response.use(function(response) {
    let el = document.querySelector('.backdrop');
    if(el){
        el.remove();
    }
    return response;
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
