import axios from 'axios';
import Vue from "vue";

export default {
    actions: {
        async getProductList(ctx, filter) {
            await axios.get('/product/list?limit=' + filter.limit
                + '&title=' + filter.search.title
                + '&barcode=' + filter.search.barcode
                + '&priceFrom=' + filter.search.priceFrom
                + '&priceTo=' + filter.search.priceTo
                + '&weightFrom=' + filter.search.weightFrom
                + '&weightTo=' + filter.search.weightTo
                + '&categories=' + this.getters.filterCategories,
            ).then(async res => {
                ctx.commit('updateProductList', res.data.data)
            });
        },
        async getProductListNoFilter(ctx, region = 77){
            await axios.get('/product/list-no-filter?region=' + region).then(async res => {
                ctx.commit('updateProductListNoFilter', res.data.data.data)
            });
        },
        async getProduct(ctx, productId){
            await axios.get('/product/get?id=' + productId).then(async res => {
                ctx.commit('updateProduct', res.data.data);
            });
        },
        async saveProduct(ctx, item){
            await axios.post('/product/save', item).then(async res => {
                if (res.data.status) {
                    Vue.$toast.success('Товар успешно обновлён!');
                } else {
                    Vue.$toast.error(res.data.data);
                }
            });
        },
        async editSortProduct(ctx, data){
            await axios.post('/product/edit-sort', data).then(async res => {
                if(res.data.result == 'ok'){
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async changeActiveProduct(ctx, id){
            await axios.get('/product/change-visible?id=' + id).then(async res => {
                if(res.data.result == 'ok'){
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async changeProperty(ctx, data){
            await axios.post('/product/change-property', data).then(async res => {
                if(res.data.result == 'ok'){
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async deleteImageProduct(ctx, data){
            await axios.post('/product/delete-image', data).then(async res => {
                if(res.data.result == 'ok'){
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
    },
    mutations: {
        updateProductList(state, products) {
            state.products = products.data.products;
            state.categories = products.data.categories;
            state.totalCount = products.totalCount;
        },
        updateProductListNoFilter(state, data){
            state.productsNoFilter = data.products;
        },
        updateFilterCategories(state, filter){
            state.filterCategories = filter;
        },
        updateProduct(state, product){
            state.product = product;
        },
    },
    state: {
        products: [],
        productsNoFilter: [],
        product: [],
        categories: [],
        filterCategories: '',
        totalCount: 0,
    },
    getters: {
        productList(state) {
            return state.products;
        },
        productListNoFilter(state){
            return state.productsNoFilter;
        },
        product(state){
            return state.product;
        },
        productCount(state) {
            return state.products.length;
        },
        productCategories(state){
            return state.categories;
        },
        filterCategories(state){
            return state.filterCategories;
        },
    }
}