<template>
  <section class="main">
    <div class="table-header">Поиск товаров в интеграции Yandex.Eda</div>
    <div class="form-filter">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label">Поиск
              <vue-custom-tooltip label="Поиск товара по названию"><i class="far fa-question-circle"></i>
              </vue-custom-tooltip>
            </label>
            <input type="text" class="form-control" placeholder="Введите название" v-model="filter.search"/>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="btn-group mt-2">
          <button type="button" class="btn btn-success" @click="search">Найти</button>
        </div>
      </div>
    </div>
    <table class="table table-hover table-bordered mt-2">
      <thead>
      <tr>
        <th scope="col" class="text-center">#</th>
        <th scope="col" class="text-center">Название товара</th>
        <th scope="col" class="text-center">Должен показываться</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in yandexSearch.items" :key="item.id">
        <th scope="row" class="text-center">{{ index + 1 }}</th>
        <td class="text-center">{{ item.name }}</td>
        <td v-if="item.available > 0" style="background: green; color: white;" class="text-center">Да</td>
        <td v-else class="text-center" style="background: red; color: white;">Нет</td>
      </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import Vue from "vue";
import {mapGetters} from "vuex";

export default {
  name: 'integration-yandex',
  data: function () {
    return {
      filter: {
        search: ''
      }
    }
  },
  computed: mapGetters(['yandexSearch']),
  async mounted() {},
  methods: {
    search: function () {
      if(this.filter.search < 3){
        Vue.$toast.error('Введите минимум 3 символа');
      }else{
        this.$store.dispatch('searchYandexProducts', this.filter);
      }
    },
  }
}
</script>
