<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Просмотр категории: {{ this.category.name }}</div>
      <div class="btn-group">
        <a class="btn btn-success" @click="openModal('createSubCategory')">Добавить подкатегорию</a>
        <router-link class="btn btn-secondary" :to="'/edit-category/' + this.id">Редактировать категорию</router-link>
        <router-link class="btn btn-outline-primary" to="/list-category">Назад</router-link>
      </div>
    </div>

    <div class="drag-n-drop-sub-category card col-md-4">
      <div class="card-header">
        Порядок подкатегорий
      </div>
      <div class="card-body-p-0" v-if="this.sortSubCategory.length > 0">
        <Container @drop="onDropCategory">
          <Draggable v-for="(item, index) in this.sortSubCategory" :key="item.id">
            <router-link :to="'/edit-sub-category/' + item.id" class="drag-item">
              {{ index + 1 }}. {{item.name }}
            </router-link>
          </Draggable>
        </Container>
      </div>
      <div class="card-body" v-else>
        <p>Подкатегорий не найдено</p>
      </div>
      <div class="card-footer text-center">
        <button class="btn btn-success" @click="saveSortCategory">Сохранить</button>
      </div>
    </div>
    <div class="mt-2">
      <div class="drag-n-drop-sub-category card col-md-6">
        <div class="card-header">
          Порядок товаров
        </div>
        <div class="card-body-p-0" v-if="this.sortProducts.length > 0">
          <Container @drop="onDropProduct">
            <Draggable v-for="(item, index) in this.sortProducts" :key="item.id">
              <a class="drag-item">
                {{ index + 1 }}. {{ item.title }}
              </a>
            </Draggable>
          </Container>
        </div>
        <div class="card-body" v-else>
          <p>Товаров не найдено</p>
        </div>
        <div class="card-footer text-end">
          <button class="btn btn-success" @click="saveSortProducts">Сохранить</button>
        </div>
      </div>
    </div>
    <create-sub-category :id="this.id" v-show="this.$store.state.modal.createSubCategory"/>
  </section>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
import { applyDrag } from "../../utils/helpers";
import {mapGetters} from "vuex";
import CreateSubCategory from "../../components/modal/category/create-sub-category";

export default {
  name: 'view-category',
  components: {CreateSubCategory, Container, Draggable },
  props: ['id'],
  data: function () {
    return {
      sortSubCategory: [],
      sortProducts: [],
    }
  },
  computed: {
    ...mapGetters(['category']),
  },
  async mounted() {
    await this.getIndexInfo();
    this.$root.$on('updateSubCategoriesView', () => {
      this.getIndexInfo();
    });
  },
  methods: {
    getIndexInfo: function(){
      this.$store.dispatch('getCategoriesInfo', this.id).then(() => {
        this.sortSubCategory = this.category.subCategories;
        this.sortProducts = this.category.products;
      });
    },
    saveSortCategory: function(){
      this.$store.dispatch('editSubCategoriesSort', {'sort': this.sortSubCategory, 'cat_id': this.id});
    },
    saveSortProducts: function(){
      this.$store.dispatch('editProductsCategoriesSort', {'sort': this.sortProducts, 'cat_id': this.id});
    },
    onDropCategory(dropResult) {
      this.sortSubCategory = applyDrag(this.sortSubCategory, dropResult);
    },
    onDropProduct(dropResult) {
      this.sortProducts = applyDrag(this.sortProducts, dropResult);
    },
    openModal: function(name){
      this.$store.dispatch('openModal', name);
    },
  },
}
</script>
