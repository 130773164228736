import axios from 'axios';
import Vue from "vue";
import router from '../../../router';

export default {
    actions: {
        async listMailing(ctx) {
            const res = await axios.get('/mailing/list-mailing');
            ctx.commit('updateMailingList', res.data.data.mailing);
        },
        async getClientsForMailing(ctx, data) {
            const res = await axios.get('/mailing/get-clients-for-mailing?ordercountbefore=' + data.orderCountBefore
                + '&ordercountafter=' + data.orderCountAfter
                + '&avgcheckbefore=' + data.avgCheckBefore
                + '&avgcheckafter=' + data.avgCheckAfter
                + '&lastorderbefore=' + data.lastOrderBefore
                + '&lastorderafter=' + data.lastOrderAfter);
            ctx.commit('updateMailingCountClients', res.data.data.count);
        },
        async createMailingBeta(ctx, data) {
            let formData = new FormData();
            formData.append('subject', data.mailing.subject);
            formData.append('pre_header', data.mailing.preHeader);
            formData.append('products', JSON.stringify(data.mailing.products));
            formData.append('info', data.mailing.info);
            formData.append('banner', data.mailing.banner);
            formData.append('type', data.mailing.type);
            const res = await axios.post('/mailing/create-beta',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            if (res.data.result == 'ok') {
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async createMailing(ctx, data) {
            const res = await axios.post('/mailing/create-mailing', data);
            if (res.data.result == 'ok') {
                router.go(-1);
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async listLetters(ctx) {
            const res = await axios.get('/mailing/list-letter');
            ctx.commit('updateLetters', res.data.data.letters);
        },
        async getLetter(ctx, id) {
            const res = await axios.get('/mailing/get-letter?id=' + id);
            ctx.commit('updateLetter', res.data.data.letter);
        },
        async saveLetter(ctx, data) {
            const res = await axios.post('/mailing/save-letter', data);
            if (res.data.result == 'ok') {
                router.go(-1);
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async editLetter(ctx, data) {
            const res = await axios.post('/mailing/edit-letter', data);
            if (res.data.result == 'ok') {
                router.go(-1);
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async listLetterTemplate(ctx) {
            const res = await axios.get('/mailing/list-letter-template');
            ctx.commit('updateLettersTemplate', res.data.data.lettersTemplate);
        },
        async getLetterTemplate(ctx, id) {
            const res = await axios.get('/mailing/get-letter-template?id=' + id);
            ctx.commit('updateLetterTemplate', res.data.data.letterTemplate);
        },
        async saveLetterTemplate(ctx, data) {
            const res = await axios.post('/mailing/save-letter-template', data);
            if (res.data.result == 'ok') {
                router.go(-1);
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
        async editLetterTemplate(ctx, data) {
            const res = await axios.post('/mailing/edit-letter-template', data);
            if (res.data.result == 'ok') {
                router.go(-1);
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
    },
    mutations: {
        updateMailingList(state, data) {
            state.mailings = data;
        },
        updateLetters(state, data) {
            state.letters = data;
        },
        updateLetter(state, data) {
            state.letter = data;
        },
        updateLettersTemplate(state, data) {
            state.lettersTemplate = data;
        },
        updateLetterTemplate(state, data) {
            state.letterTemplate = data;
        },
        updateMailingCountClients(state, data) {
            state.countClients = data;
        },
    },
    state: {
        mailings: [],
        letters: [],
        letter: [],
        letterTemplate: [],
        lettersTemplate: [],
        countClients: 0,
        mail: [],
    },
    getters: {
        mailingList: state => state.mailings,
        letterInfo: state => state.letter,
        lettersInfo: state => state.letters,
        letterTemplateInfo: state => state.letterTemplate,
        lettersTemplateInfo: state => state.lettersTemplate,
        mailInfo: state => state.mail,
        countClients: state => state.countClients
    }
}